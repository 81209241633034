<template>
  <b-modal id="bcd-request-form" size="xl" @shown="update">
    <template #modal-title>
      {{ $t('components.bcd_request_form.title') }}
    </template>
    <b-overlay rounded="sm">
      <b-form>
        <b-form-group :label="$tc('system.organizational_unit', 1)" label-for="oe-select">
          <Typeahead :data="oes" v-model="oe_search"
                     :placeholder="$tc('system.organizational_unit', 1)"
                     @select="selected_oe=$event;$nextTick(updateGroups)"
                     @input="selected_oe=null;updateGroups()"
                     :serializer="serialize_oe"
                     :min-matching-chars="-1"
                     :max-matches="-1"
                     :disabled="(oes && oes.length === 0) || loading_edit_action"
                     compact
                     ref="oe-select"
                     class="mb-3" id="oe-select"/>
        </b-form-group>
        <b-form-group :label="$tc('system.group', 1)" label-for="group-select"
                      :description="$t('components.bcd_request_form.group_assignment_note')">
          <Typeahead :data="groups" v-model="group_search"
                     :placeholder="$tc('system.group', 1)"
                     @select="selected_group=$event"
                     @input="selected_group=null"
                     :serializer="serialize_group"
                     :min-matching-chars="-1"
                     :max-matches="-1"
                     :disabled="!selected_oe || (groups && groups.length === 0) || loading_edit_action"
                     compact
                     ref="group-select"
                     id="group-select"/>
        </b-form-group>
        <b-form-group :description="$t('components.bcd_request_form.protection_requirements_note')">
          <template #label>
            {{ $t('components.bcd_request_form.protection_requirements') }}
            <a href="https://www.scc.kit.edu/dienste/4325.php" target="_blank">
              <netvs-icon icon="info"></netvs-icon>
            </a>
          </template>
          <b-form-select v-model="protection_requirements_select" :options="translated_protection_requirements"
                         :state="load_balancer_firewall_state"
                         :disabled="!selected_group || loading_edit_action">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback :state="load_balancer_firewall_state">{{ $t('components.bcd_request_form.level_2_warning') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.protection_requirements_explanation')">
          <b-form-textarea :disabled="protection_requirements_select !== 10 || loading_edit_action"
                           :placeholder="$t('components.bcd_request_form.placeholder_text')"
                           v-model="protection_requirements_explanation_text"/>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.types_of_connected_systems')"
                      :description="$t('components.bcd_request_form.types_of_connected_systems_note')">
          <b-form-select v-model="types_of_systems_select" :options="translated_types_of_systems"
                         :disabled="!protection_requirements_select || loading_edit_action"
                         @change="types_of_systems_select=$event;$nextTick(updateSystemType)">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.other_systems')"
                      :description="$t('components.bcd_request_form.other_systems_note')">
          <b-form-textarea :disabled="types_of_systems_select !== 6 || loading_edit_action"
                           :placeholder="$t('components.bcd_request_form.placeholder_text')"
                           v-model="types_of_systems_other_explanation_text"/>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.load_balancing')">
            <b-form-checkbox :disabled="types_of_systems_select !== 2 || loading_edit_action"
                             :state="load_balancer_firewall_state"
                             v-model="load_balancing_value">{{ $t('components.bcd_request_form.load_balancing_text') }}
            </b-form-checkbox>
            <b-form-invalid-feedback :state="load_balancer_firewall_state">{{ $t('components.bcd_request_form.level_2_warning') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.name_of_bcd')"
                      :description="$t('components.bcd_request_form.name_of_bcd_description')">
          <b-input-group>
            <b-form-input :placeholder="$t('components.bcd_request_form.naming_example_placeholder')"
                          :disabled="!types_of_systems_select || loading_edit_action"
                          v-model="bcd_name"/>
            <b-input-group-append>
              <b-button v-b-toggle.guide_collapse variant="outline-secondary">
                <netvs-icon icon="info"></netvs-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-collapse id="guide_collapse">
            <div class="mt-2 mb-2">
              <h5>{{ $t('components.bcd_request_form.notes_on_naming') }}</h5>
              <p>{{ $t('components.bcd_request_form.naming_scheme_usage') }}:
                <code>{{ $t('components.bcd_request_form.naming_scheme') }}</code></p>
              <p class="mb-1"><code>{{ $t('components.bcd_request_form.naming_oe') }}</code>
                {{ $t('components.bcd_request_form.naming_oe_description') }}</p>
              <p class="mb-1"><code>{{ $t('components.bcd_request_form.naming_division') }}</code>
                {{ $t('components.bcd_request_form.naming_division_description') }}</p>
              <p class="mb-1"><code>{{ $t('components.bcd_request_form.naming_function') }}</code>
                {{ $t('components.bcd_request_form.naming_function_description') }}</p>
              <p class="mb-1"><code>{{ $t('components.bcd_request_form.naming_number') }}</code>
                {{ $t('components.bcd_request_form.naming_number_description') }}</p>
            </div>
          </b-collapse>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.access_to_internet')">
          <b-form-checkbox :disabled="!(bcd_name && bcd_name.length > 0) || loading_edit_action"
                           v-model="access_to_internet_value">
            {{ $t('components.bcd_request_form.access_to_internet_text') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.access_from_internet')"
                      :description="$t('components.bcd_request_form.access_from_internet_note')">
          <b-form-checkbox :disabled="!(bcd_name && bcd_name.length > 0) || loading_edit_action"
                           v-model="access_from_internet_value">
            {{ $t('components.bcd_request_form.access_from_internet_text') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.access_from_internet_explanation')">
          <b-form-textarea :placeholder="$t('components.bcd_request_form.placeholder_text')"
                           v-model="access_from_internet_explanation_value"
                           :disabled="!access_from_internet_value || types_of_systems_select === 2 || loading_edit_action"/>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.ip_address_version')">
          <b-form-select v-model="types_of_subnets_select" :options="translated_types_of_subnets">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-alert show variant="warning">
          <b-row>
            <b-col cols="auto" class="my-auto">
              <netvs-icon icon="warning" size="2x"></netvs-icon>
            </b-col>
            <b-col class="h-100 my-auto">
              <p class="my-auto">{{ $t('components.bcd_request_form.ip_address_internal_note') }}</p>
            </b-col>
          </b-row>
        </b-alert>
        <b-form-group :label="$t('components.bcd_request_form.ipv4_subnet_size')">
          <b-form-select v-model="subnet_size_select" :options="translated_subnet_sizes">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-alert show variant="info">
          <b-row>
            <b-col cols="auto" class="my-auto">
              <netvs-icon icon="info" size="2x"></netvs-icon>
            </b-col>
            <b-col class="h-100 my-auto">
              <p class="my-auto">{{ $t('components.bcd_request_form.ipv4_subnet_size_note') }}</p>
            </b-col>
          </b-row>
        </b-alert>
        <b-form-group :label="$t('components.bcd_request_form.ipv4_subnet_size_explanation')">
          <b-form-textarea/>
        </b-form-group>
        <b-form-group :label="$t('system.dhcp')" :description="$t('components.bcd_request_form.dhcp_wish_note')">
          <b-form-checkbox>{{ $t('components.bcd_request_form.dhcp_wish') }}</b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.dhcp_own')">
          <b-form-checkbox>{{ $t('components.bcd_request_form.dhcp_own_question') }}</b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.dhcp_pool')">
          <b-form-checkbox>{{ $t('components.bcd_request_form.dhcp_pool_wish') }}</b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.dhcp_pool_size')">
          <b-form-select v-model="dhcp_pool_size_select" :options="translated_dhcp_pool_size">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.device_location')">
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.cs') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.cn') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.vm_cluster_unknown') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.vm_cluster_cs') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.vm_cluster_cn') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.scc_server_room_cs') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.scc_server_room_cn') }}</b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.device_locations.vpn_wifi_to_vlan') }}</b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.realized_services')">
          <b-form-checkbox>{{ $t('components.bcd_request_form.realizable_services.vpn2vlan') }} <a
            href="https://www.scc.kit.edu/ts-vpn2vlan" target="_blank">
            <netvs-icon icon="external_link"></netvs-icon>
          </a></b-form-checkbox>
          <b-form-checkbox>{{ $t('components.bcd_request_form.realizable_services.wifi2vlan') }} <a
            href="https://www.scc.kit.edu/ts-wifi2vlan" target="_blank">
            <netvs-icon icon="external_link"></netvs-icon>
          </a></b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('components.bcd_request_form.additional_comments')">
          <b-form-textarea/>
        </b-form-group>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import Typeahead from '@/components/Typeahead.vue'
import OUService from '@/api-services/ou.service'

export default {
  name: 'BCDRequestForm',
  components: { Typeahead },
  data() {
    return {
      oes: null,
      oe_search: '',
      selected_oe: null,
      serialize_oe: oe => `${oe.name} (${oe.short_name})`,
      groups: null,
      group_search: '',
      selected_group: null,
      serialize_group: group => group.name,
      loading_edit_action: false,
      bcd_name: null,
      load_balancing_value: null,
      access_to_internet_value: null,
      access_from_internet_value: null,
      access_from_internet_explanation_value: null,
      dhcp_pool_size_select: null,
      dhcp_pool_size_options: [
        { value: 2, translation_key: 'components.bcd_request_form.dhcp_pool_sizes.size_2' },
        { value: 3, translation_key: 'components.bcd_request_form.dhcp_pool_sizes.size_3' },
        { value: 4, translation_key: 'components.bcd_request_form.dhcp_pool_sizes.size_4' },
        { value: 5, translation_key: 'components.bcd_request_form.dhcp_pool_sizes.size_5' }
      ],
      protection_requirements_select: null,
      protection_requirements_explanation_text: null,
      protection_requirements_options: [
        { value: 1, translation_key: 'components.bcd_request_form.level_1' },
        { value: 2, translation_key: 'components.bcd_request_form.level_2' },
        { value: 10, translation_key: 'components.bcd_request_form.level_special' }
      ],
      types_of_systems_select: null,
      types_of_systems_other_explanation_text: null,
      types_of_systems_options: [
        { value: 1, translation_key: 'components.bcd_request_form.systems.clients' },
        { value: 2, translation_key: 'components.bcd_request_form.systems.server' },
        { value: 3, translation_key: 'components.bcd_request_form.systems.printer' },
        { value: 4, translation_key: 'components.bcd_request_form.systems.iot_devices' },
        { value: 5, translation_key: 'components.bcd_request_form.systems.vpn_wifi_only' },
        { value: 6, translation_key: 'components.bcd_request_form.other' }
      ],
      types_of_subnets_select: null,
      types_of_subnets_options: [
        { value: 1, translation_key: 'components.bcd_request_form.subnets_types.ipv6_only' },
        { value: 2, translation_key: 'components.bcd_request_form.subnets_types.both' }
      ],
      subnet_size_select: null,
      subnet_size_options: [
        { value: 29, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_29' },
        { value: 28, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_28' },
        { value: 27, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_27' },
        { value: 26, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_26' },
        { value: 25, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_25' },
        { value: 24, translation_key: 'components.bcd_request_form.ipv4_subnet_sizes.size_24' }
      ]
    }
  },
  computed: {
    translated_dhcp_pool_size() {
      return this.translateOptions(this.dhcp_pool_size_options)
    },
    translated_protection_requirements() {
      return this.translateOptions(this.protection_requirements_options)
    },
    translated_types_of_systems() {
      return this.translateOptions(this.types_of_systems_options)
    },
    translated_types_of_subnets() {
      return this.translateOptions(this.types_of_subnets_options)
    },
    translated_subnet_sizes() {
      return this.translateOptions(this.subnet_size_options)
    },
    load_balancer_firewall_state() {
      if (this.types_of_systems_select === 2 && this.protection_requirements_select === 2 && this.load_balancing_value === true) {
        return false
      }
      return null
    }
  },
  methods: {
    async update() {
      await this.updateOEs()
    },
    async updateOEs() {
      this.clearOE()
      this.loading++
      const response = await OUService.listOwn(this.$store.state)
      this.oes = response.data.unit_list
      if (this.oes.length === 1) {
        this.selected_oe = this.oes[0]
        this.oe_search = this.serialize_oe(this.selected_oe)
        await this.updateGroups()
      }
      this.loading--
    },
    async updateGroups() {
      this.clearGroup()
      if (!this.selected_oe) return
      this.loading++
      const response = await OUService.getOU(this.$store.state, this.selected_oe.short_name.toUpperCase())
      this.groups = response.data.group_list
      if (this.groups.length === 1) {
        this.selected_group = this.groups[0]
        this.group_search = this.serialize_group(this.selected_group)
      }
      this.loading--
    },
    async updateSystemType() {
      // Clear the value of the load balancers if the type of system has changed
      this.clearLoadBalancer()
      // Enable access from the internet if server network
      if (this.types_of_systems_select === 2) {
        this.access_from_internet_value = true
      } else {
        this.clearAccessFromInternet()
      }
      this.clearAccessToInternet()
    },
    clear() {
      // Clear everything
      this.clearOE()
      this.clearGroup()
      this.clearLoadBalancer()
      this.clearAccessToInternet()
      this.clearAccessFromInternet()
    },
    clearOE() {
      this.oes = null
      this.selected_oe = null
      this.oe_search = ''
    },
    clearGroup() {
      this.group_search = null
      this.selected_group = null
      this.group_search = ''
    },
    clearLoadBalancer() {
      this.load_balancing_value = null
    },
    clearAccessToInternet() {
      this.access_to_internet_value = null
    },
    clearAccessFromInternet() {
      this.access_from_internet_value = null
    },
    translateOptions(items) {
      // Iterate over the list of options
      const copy_items = JSON.parse(JSON.stringify(items))
      for (const copy_element of copy_items) {
        // Check if the item is an object to apply translations to
        if (typeof copy_element === 'object') {
          if (Object.hasOwn(copy_element, 'translation_key')) {
            // Generate the text of the item dynamically
            copy_element.text = this.$t(copy_element.translation_key)
          }
        }
      }
      return copy_items
    }
  }
}
</script>

<style scoped>

</style>
